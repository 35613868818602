<template>
  <div class="page1">
    <EForm
            :formColumns="formColumns"
            :rowSize="4"
            :optionsBtn="true"
            :actionBtn="false"
            :formData="searchForm"
            ref="form"
            @onSearch="onSearch"
            :searchFlag="true"
            :exportShow="false"
    >
    </EForm>
    <!--    <el-button type="primary" @click="dialogVisible = true" size="small">增加</el-button>-->
    <!--     <EButton type="primary" @click="dialogVisible = true">增加</EButton>-->
    <ETable
            :tableTitle="tableTitle"
            :tableData="tableData"
            :needPagination="true"
            :count="count"
            @changePage="changePage"
            @changeSize="changeSize"
            :page="searchForm.offset"
            :page_size="searchForm.limit"
    >
    <div slot="sex" slot-scope="scope">
        {{scope.data.sex==1?'男':'女'}}
    </div>
      <div slot="inOut" slot-scope="scope">
        {{scope.data.inOut==1?'进':'出'}}
      </div>
      <div slot="verifType" slot-scope="scope">
          {{scope.data.verifType==1?'人脸识别':'-'}}
      </div>
      <div slot="urlImg" slot-scope="scope">
        <viewer :images="[scope.data.urlImg]">
          <img :src="scope.data.urlImg" alt="" class="img">
        </viewer>
      </div>
    </ETable>
  </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import Http from '@/service/http';
  import EDialog from '@/components/EDialog';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import { identity_type, status } from '@/assets/js/config';
  import vxRule from '@/assets/js/formValidate';
  import { otherMixin } from '@/components/mixin';
  import {mapGetters} from 'vuex'
  export default {
    name: 'shopInfo',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '姓名',
            prop: 'name',
          },
          {
            label: '手机',
            prop: 'mobile',
          },
          {
            label: '身份证号',
            prop: 'cardNo',
          },
          {
            label: '性别',
            prop: 'sex',
            type:'slot'
          },
          {
            label: '进/出',
            prop: 'inOut',
            type:'slot'
          },
          {
            label: '通道名称',
            prop: 'channelName',
          },
          {
            label: '体温',
            prop: 'temperature',
          },
          {
            label: '验证方式',
            prop: 'verifType',
            type:'slot'
          },
          {
            label: '进场时间',
            prop: 'passingTime',
          },
          {
            label: '人脸图片',
            prop: 'urlImg',
            type:'slot',
          },
        ],
        formColumns: [
          {
            title: '姓名',
            type: 'text',
            property: 'name',
            show: true,
          },

          {
            title: '手机号  ',
            type: 'text',
            property: 'mobile',
            show: true,
          },
          {
            title: '身份证号',
            type: 'text',
            property: 'cardNo',
            show: true,
          },
          {
            title: '性别',
            type: 'select',
            property: 'sex',
            show: true,
            options: [
              {
                label: '全部',
                value: '',
              },
              {
                label: '男',
                value: 1,
              },
              {
                label: '女',
                value:2,
              },
            ],
          },
          {
            title: '进出 ',
            type: 'select',
            property: 'inOut',
            show: true,
            options: [
              {
                label: '全部',
                value: '',
              },
              {
                label: '进',
                value: 1,
              },
              {
                label: '出',
                value: 2,
              },
            ],
          },
          {
            title: '进场起始时间',
            type: 'datePicker',
            property: 'timeStart',
            show: true,
          },
          {
            title: '进场截止时间',
            type: 'datePicker',
            property: 'timeEnd',
            show: true,
          },
          {
            title: '通道名称',
            type: 'input',
            property: 'channelName',
            show: true,
          },
        ],
        tableData: [],
        searchForm: {
          name: null,
          mobile: null,
          cardNo: null,
          sex: null,
          inOut: null,
          timeStart: null,
          timeEnd: null,
          channelName: null,
          offset: 1,
          limit: 10,
        },
        count: null,
        exportData: {},

      };
    },
    watch: {},
    created() {
      this.getData();

    },
    components: { ETable, EDialog, EButton, EForm },
    computed:{
      ...mapGetters(['buttonList']),
    },
    beforeMount() {},
    methods: {


      onSearch() {
        this.searchForm.offset = 1;
        this.getData();
      },
      changePage(current) {
        this.searchForm.offset = current;
        this.getData();
      },
      changeSize(size) {
        this.searchForm.limit = size;
        this.getData();
      },

      async getData() {
        let {
          name,
          mobile,
          cardNo,
          sex,
          inOut,
          timeStart,
          timeEnd,
          channelName,
          offset,
          limit,
        } = this.searchForm;
        let res = await Http.faceList({
          name,
          mobile,
          cardNo,
          sex,
          inOut,
          timeStart,
          timeEnd,
          channelName,
          offset,
          limit,
        });
        if (res.code == 200) {
          this.tableData = res.data.rows;
          this.count = res.data.total;
        }
      },
    }
  };
</script>

<style lang="scss" scoped>
  .ws {
    width: 100%;
  }
  .img{
    width:100px;
  }
</style>
